import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog6',
  templateUrl: './blog6.component.html',
  styleUrls: ['./blog6.component.css']
})
export class Blog6Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'How can small hotels improve their profit margins?',
      answer: 'Small hotels can improve their profit margins by focusing on cost control, optimizing pricing strategies, and enhancing guest experiences to encourage repeat business. By implementing these strategies, small hotels can significantly improve their hotel financial performance.',
      open: false
    },
    {
      question: 'What tools can help in monitoring and boosting hotel profit margins?',
      answer: 'Tools like GoFryDay’s hospitality management software can help monitor and boost profit margins by streamlining operations, reducing costs, and optimizing revenue management.',
      open: false
    },
    {
      question: 'How often should hotels review their profit margins?',
      answer: 'Hotels should review their profit margins at least quarterly to ensure they are on track to meet financial goals and to make adjustments as needed based on market conditions.',
      open: false
    }
  ];

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Hotel-Profit-Margin',
      'Hotel-Profit-Margin-Calculated',
      'Hotel-Good-Net-Profit-Margin',
      'Hotel-Factors-Affecting-Profit-Margin',
      'Boost-Hotel-Profit-Margins',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewLinkedIn() {
    window.open('https://www.linkedin.com/shareArticle?mini=true&url=https://gofryday.com/blog/hotel-profit-margin', "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    this.disabled = true;
    this.isPopupOpen = true;
    let obj = {
      "NAME": this.contactUsForm.value.name,
      "EMAIL": this.contactUsForm.value.email,
      "MESSAGE": this.contactUsForm.value.message,
      "TNC": true
    }
    this.hotelService.contactUs(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        if (!!data?.object?.ID) {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '50%',
            data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(() => {
            this.isPopupOpen = false;
          });
        } else {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        }
        this.contactUsForm.reset();
      },
      error: (e) => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewHotelProfitMargin() {
    this.scrollToSection('Hotel-Profit-Margin');
  }

  viewHotelCalculatedProfitMargin() {
    this.scrollToSection('Hotel-Profit-Margin-Calculated');
  }

  viewGoodNetProfitMargin() {
    this.scrollToSection('Hotel-Good-Net-Profit-Margin');
  }

  viewFactorsAffecting() {
    this.scrollToSection('Hotel-Factors-Affecting-Profit-Margin');
  }

  viewBoostHotelProfitMargins() {
    this.scrollToSection('Boost-Hotel-Profit-Margins');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
