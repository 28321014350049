<app-header></app-header>
<div class="container">
    <div class="clio-container width100" fxLayout="row">
        <div class="clio-child" fxLayout="column" fxLayoutGap="3%">
            <h1 class="clio-child-heading">CLIO Admin Panel-Best Hotel Business Intelligence Software
            </h1>
            <div *ngIf="mobile" style="align-items: center;">
                <img src="/assets/mobile-images/Clio-image.png" />
            </div>
            <div class="clio-child-text">A centralized panel to view, manage, and optimize guest requests, reducing
                human resource dependency and leading to a significant decrease in operational costs
            </div>
            <div>
                <button class="book-btn"
                    onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                    Book A Demo Today
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="clio-image" src="/assets/images/Clio-image.png" alt="Clio" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="clio-child-heading2">
            Key features of our hotel Business Intelligence Software
        </h2>
        <div *ngIf="!mobile">
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book A Demo Today
            </button>
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Inventory Management.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Hotel Inventory Management
                    </h3>
                    <div class="why-choose-text">View rooms, update rates, manage bookings – all from one dashboard.
                        Effortless hotel inventory at your fingertips
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Complaint Management.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Guest Complaint Management
                    </h3>
                    <div class="why-choose-text">Frustrated guests don't have to mean lost business. Our Complaint
                        management software lets you address concerns quickly, assign staff, and keep guests in the loop
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Hospitality Operations.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Seamless Hospitality Operations
                    </h3>
                    <div class="why-choose-text">Assign cleaning duties, schedule maintenance, and track progress – all
                        within our Hospitality Operations software. Guests submit requests directly, enabling staff to
                        respond quickly and efficiently 
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Tracking.png" alt="" />
                    <h3 class="why-choose-content-head">
                        360 degree tracking and visibility 
                    </h3>
                    <div class="why-choose-text">We provide a single platform to track guest requests, inventory,
                        housekeeping and more. Gain real-time insights to optimize operations, make data-driven
                        decisions
                    </div>
                </div>
                <div *ngIf="mobile">
                    <button class="book-btn"
                        onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                        Book A Demo Today
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="clio-child-heading2">Benefits Of our hospitality Business Intelligence software</h2>
        <div class="clio-child-text2">
            We simplify operations and enhance guest satisfaction. Our software saves you time and money while
            streamlining your hotel's workflow
        </div>
        <div fxLayout="row" class="clio-features-content" fxLayoutAlign="center center">
            <div *ngIf="mobile" class="feature-image-container" fxLayout="column" fxLayoutAlign="center center">
                <div class="feature-image-circle">
                    <img class="feature-mobile-image" src="assets/mobile-images/Clio-Feature-image.png"
                        alt="Feature Image">
                </div>
            </div>
            <div fxLayout="column" class="left-side-points" fxLayoutGap="5%">
                <div fxLayout="column">
                    <h3 class="features-header">
                        Increased Efficiency
                    </h3>
                    <div class="features-text">
                        CLIO centralizes operations, enabling staff to quickly manage bookings, track tasks, and monitor
                        performance. This boosts efficiency and reduces administrative workload
                    </div>
                </div>
                <div fxLayout="column">
                    <h3 class="features-header">
                        Reduced Operational Costs
                    </h3>
                    <div class="features-text">
                        Automated processes and real-time tracking cut down on unnecessary manual labor expenses,
                        leading to significant cost savings
                    </div>
                </div>
                <div fxLayout="column">
                    <h3 class="features-header">
                        Improved Guest Experience 
                    </h3>
                    <div class="features-text">
                        By streamlining request management, CLIO ensures guests receive prompt attention and customized
                        service, improving their overall experience and comfort.   
                    </div>
                </div>
                <div fxLayout="column">
                    <h3 class="features-header">
                        Better Decision-Making
                    </h3>
                    <div class="features-text">
                        Clear, real-time insights help you make quick, informed decisions, improving efficiency and
                        effectiveness
                    </div>
                </div>
            </div>
            <div *ngIf="!mobile" fxFlex="48%" class="feature-image-container" fxLayout="column"
                fxLayoutAlign="center center">
                <div class="feature-image-circle">
                    <img class="feature-mobile-image" src="assets/images/Clio-Feature-image.png" alt="Feature Image">
                </div>
            </div>
        </div>
        <div>
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book A Demo Today
            </button>
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="clio-child-heading3">
            How Our Hospitality Management Software Works?
        </h2>
        <div class="clio-child-text2">
            Simplify complex tasks with our user-friendly admin panel. Centralize guest data, streamline bookings, and
            personalize interactions. Real-time insights and support tools optimize your operations
        </div>
        <div>
            <video #clioVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
            </video>
        </div>
        <div>
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book A Demo Today
            </button>
        </div>
    </div>
    <div fxLayout="row" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="!mobile">
        <div>
            <h2 class="faq-header">FAQ’S</h2>
        </div>
        <div fxLayout="column" class="faq-content">
            <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
                <div class="faq-question">
                    {{ faq.question }}
                    <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
                </div>
                <div class="faq-answer" *ngIf="faq.open">
                    {{ faq.answer }}
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="mobile">
        <div>
            <h2 class="faq-header">FAQ’S</h2>
        </div>
        <div fxLayout="column" class="faq-content">
            <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
                <div class="faq-question">
                    {{ faq.question }}
                    <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
                </div>
                <div class="faq-answer" *ngIf="faq.open">
                    {{ faq.answer }}
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>