<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            What Is Hospitality Management?
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">30 Aug 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Hospitality Management.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Hospitality Management.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewHospitalityManagement()"
                    [class.active]="activeSection === 'Hospitality-Management'">What is a Hospitality
                    Management?</span></li>
            <li><span (click)="viewDifference()" [class.active]="activeSection === 'Difference'">Difference Between
                    Hospitality <br>
                    Management and Hotel Management
                </span></li>
            <li><span (click)="viewHospitalityManagementJobs()"
                    [class.active]="activeSection === 'Hospitality-Management-Jobs'">Top Hospitality Management
                    Jobs</span></li>
            <li><span (click)="viewCoreSkills()" [class.active]="activeSection === 'Core-Skills'">Core Skills in
                    Hospitality Management</span></li>
            <li><span (click)="viewCareerOpportunities()"
                    [class.active]="activeSection === 'Career-Opportunities'">Hospitality Management Degree <br>
                    and Career Opportunities
                </span>
            </li>
            <li><span (click)="viewChallenges()" [class.active]="activeSection === 'Challenges'">Challenges in
                    Hospitality
                    Management</span>
            </li>
            <li><span (click)="viewFutureTrends()" [class.active]="activeSection === 'Future-Trends'">Future Trends in
                    Hospitality Management</span>
            </li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn"
                    onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div id="Hospitality-Management" fxLayout="column" class="info-content-revolution" fxLayoutGap="2%"
            fxFlex="65%">
            <div class="info-text">Hospitality management is both art and science of managing multiple factors of the
                hospitality industry. This includes hotels, restaurants, event organizations to tourism services.
                Hospitality management is making sure that guests have the best possible experience.<br>
                When it comes to the service industry, hospitality management is one of the aspects that one cannot
                ignore. It’s not only about rendering a service; it’s about ensuring that guests are so delighted with
                an experience that they come back for more.<br>
                It does not matter if it is an individual who is checking into the hotel or it is a corporate event, the
                standard of hospitality management available will correlate with the experience. This is why
                professionals in this area of work are highly regarded as they can convert even the most mundane of
                services into the most remarkable experience.
            </div>
            <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/Hospitality-management-subimage.png">
            </div>
            <div *ngIf="mobile" style="display: flex;justify-content: center;">
                <img src="assets/mobile-images/Hospitality-management-subimage.png">
            </div>
        </div>
        <div id="Difference" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Difference Between Hospitality Management and Hotel
                Management</h2>
            <div class="info-text">While the terms "hospitality management" and "hotel management" are often used
                interchangeably, they’re not the same. Hospitality management is a broad field that encompasses various
                segments, including hotels, restaurants, resorts, event planning, and tourism. It focuses on managing
                operations across multiple aspects of the service industry.<br>
                However, hotel management is concerned with only a particular segment of hospitality management. This
                involves the organizational structure of a hotel or resort daily. Such activities include staff
                management, budgeting, and provision of services to ensure that visitors are happy while residing in the
                accommodation. Although hotel management is a branch of hospitality management, the other encompasses a
                bigger scope of industries and services.
            </div>
            <div class="demosection">
                <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                    <div class="demo-text">
                        Delight your guests with AI-powered experiences
                    </div>
                    <div>
                        <button class="book-btn"
                            onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="Hospitality-Management-Jobs" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Top Hospitality Management Jobs</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Lodging Management
                </h3>
                <div class="info-text">Lodging management is a cornerstone of the hospitality industry. Lodging managers
                    are responsible for overseeing the operations of hotels, motels, and other types of accommodation.
                    Their primary goal is to ensure that guests have a comfortable and enjoyable stay. This involves
                    managing the front desk, housekeeping, maintenance, and other departments within the property.
                </div>
                <div class="info-text"><b>Responsibilities of Lodging Managers:</b>
                    <ol class="list">
                        <li class="sub-list">Supervising staff and ensuring they provide excellent customer service</li>
                        <li class="sub-list">Managing budgets and financial records.</li>
                        <li class="sub-list">Overseeing maintenance and housekeeping to ensure a clean and safe
                            environment.</li>
                        <li class="sub-list">Addressing guest complaints and resolving issues quickly and efficiently.
                        </li>
                        <li class="sub-list">Marketing the property to attract more guests and increase occupancy rates.
                        </li>
                    </ol>
                </div>


                <!-- <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/data-graph.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/data-graph.png">
                </div> -->
                <!-- <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight your guests with AI-powered experiences
                        </div>
                        <div>
                            <button class="book-btn"
                                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Food and Beverage Management
                </h3>
                <div class="info-text">Food and beverage management is another vital area within hospitality management.
                    This role involves overseeing all aspects of food and drink services, whether in a restaurant,
                    hotel, or catering company. Food and beverage managers are responsible for ensuring that guests
                    enjoy high-quality meals and drinks in a pleasant setting.
                </div>
                <div class="info-text"><b> Key Tasks and Skills Required:</b>
                    <ol class="list">
                        <li class="sub-list">Planning menus in collaboration with chefs and bartenders.</li>
                        <li class="sub-list">Managing inventory and ensuring that supplies are stocked.</li>
                        <li class="sub-list">Hiring and training kitchen and service staff.</li>
                        <li class="sub-list">Ensuring compliance with health and safety regulations.</li>
                        <li class="sub-list">Handling customer feedback and making improvements to services.
                        </li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Event Management
                </h3>
                <div class="info-text">Event management within hospitality involves planning and executing events such
                    as weddings, conferences, and corporate meetings. This field requires a keen eye for detail, strong
                    organizational skills, and the ability to manage multiple tasks simultaneously.
                </div>
                <div class="info-text"><b>Career Opportunities in Event Management:</b>
                    <ol class="list">
                        <li class="sub-list">Event Coordinator: Organizes and manages the logistics of events.</li>
                        <li class="sub-list">Wedding Planner: Specializes in planning and coordinating weddings.</li>
                        <li class="sub-list">Corporate Event Manager: Focuses on organizing events for businesses, such
                            as conferences and seminars.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Tourism Management
                </h3>
                <div class="info-text">Tourism management is closely linked to hospitality management, as both involve
                    creating memorable experiences for guests. Tourism managers work to attract visitors to a particular
                    location, whether it’s a city, resort, or tourist attraction.
                </div>
                <div class="info-text"><b>Key Aspects of Managing Tourism Services:</b>
                    <ol class="list">
                        <li class="sub-list">Developing tourism strategies to boost visitor numbers.</li>
                        <li class="sub-list">Collaborating with local businesses to enhance the tourist experience.</li>
                        <li class="sub-list">Managing tourist attractions and ensuring they are well-maintained.</li>
                        <li class="sub-list">Promoting sustainable tourism practices to protect natural and cultural
                            resources.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">5. Resort Management
                </h3>
                <div class="info-text">Resort management focuses on the luxury segment of the hospitality industry,
                    managing high-end resorts and other upscale establishments. This role requires a deep understanding
                    of luxury services and the ability to cater to discerning guests who expect top-notch amenities and
                    personalized service.
                </div>
                <div class="info-text"><b>Managing Resorts and High-End Establishments:</b>
                    <ol class="list">
                        <li class="sub-list">Ensuring that the resort offers exceptional services and experiences.</li>
                        <li class="sub-list">Managing spa, golf, and other recreational facilities.</li>
                        <li class="sub-list">Overseeing VIP guest services and ensuring personalized attention.</li>
                        <li class="sub-list">Implementing marketing strategies to attract high-end clientele.</li>
                    </ol>
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Resort management.png">
                </div>
                <div *ngIf="mobile" style="display: flex;justify-content: center;">
                    <img src="assets/mobile-images/Resort management.png">
                </div>
            </div>
        </div>

        <div id="Core-Skills" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Core Skills in Hospitality Management</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Customer Service
                </h3>
                <div class="info-text">Exceptional customer service is the heart of hospitality management. Whether
                    you're managing a hotel, restaurant, or event, the way you treat your guests can make or break their
                    experience. It's about anticipating their needs, resolving issues quickly, and going above and
                    beyond to make them feel valued.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Leadership and Team Management
                </h3>
                <div class="info-text">Effective leadership is crucial in hospitality management. Leading a team of
                    diverse individuals requires clear communication, empathy, and the ability to motivate and inspire
                    others. A good leader ensures that every team member understands their role and contributes to the
                    overall success of the organization.
                </div>
                <div class="info-text"><b>Tips for Effective Team Management:</b>
                    <ol class="list">
                        <li class="sub-list">Encourage open communication and feedback.</li>
                        <li class="sub-list">Set clear goals and expectations.</li>
                        <li class="sub-list">Provide regular training and development opportunities.</li>
                        <li class="sub-list">Recognize and reward good performance.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Financial Management
                </h3>
                <div class="info-text">In hospitality management, financial responsibilities are vast, ranging from
                    budgeting and forecasting to cost control and profit maximization. A strong grasp of financial
                    management ensures that operations run smoothly and profitably.
                </div>
                <div class="info-text"><b>Overview of Financial Responsibilities:</b>
                    <ol class="list">
                        <li class="sub-list">Creating and managing budgets for various departments.</li>
                        <li class="sub-list">Analyzing financial reports to identify areas for improvement.</li>
                        <li class="sub-list">Implementing cost-control measures without compromising service quality.
                        </li>
                        <li class="sub-list">Maximizing revenue through strategic pricing and sales initiatives.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Marketing and Sales
                </h3>
                <div class="info-text">Marketing plays a crucial role in hospitality management, helping to attract
                    guests, increase bookings, and boost revenue. Effective marketing strategies can differentiate your
                    establishment from competitors and build a loyal customer base.
                </div>
                <div class="info-text"><b>Role of Marketing in Hospitality Management:</b>
                    <ol class="list">
                        <li class="sub-list">Developing targeted marketing campaigns to attract specific customer
                            segments.</li>
                        <li class="sub-list">Utilizing social media and digital platforms to reach a wider audience.
                        </li>
                        <li class="sub-list">Creating promotional offers and packages to drive bookings.</li>
                        <li class="sub-list">Building and maintaining strong relationships with guests through loyalty
                            programs.</li>
                    </ol>
                </div>
                <div class="demosection">
                    <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Learn How GoFryDay Helps Hotels Deliver Better Service In Real-Time.
                        </div>
                        <div>
                            <button class="book-btn"
                                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="Career-Opportunities" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Hospitality Management Degree and Career Opportunities</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Degrees and Certifications
                </h3>
                <div class="info-text">To succeed in hospitality management, a formal education is often essential.
                    Various degrees and certifications are available, ranging from associate degrees to master's
                    programs, each offering a different level of depth and specialization.
                </div>
                <div class="info-text"><b>How to Choose the Right Educational Path:</b>
                    <ol class="list">
                        <li class="sub-list">Consider your career goals and the specific area of hospitality you're
                            interested in.</li>
                        <li class="sub-list">Look for programs that offer practical experience and internships.</li>
                        <li class="sub-list">Research the reputation of the institution and the success of its
                            graduates.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Career Opportunities
                </h3>
                <div class="info-text">Hospitality management offers a wide range of career opportunities, from hotel
                    and restaurant management to tourism and event planning. The industry is known for its growth
                    potential, with numerous opportunities for advancement as you gain experience.
                </div>
                <div class="info-text"><b>Growth Prospects in the Industry:</b>
                    <ol class="list">
                        <li class="sub-list">As the global travel and tourism industry continues to expand, so do the
                            opportunities in hospitality management.</li>
                        <li class="sub-list">With the right skills and experience, you can advance to senior management
                            roles, such as General Manager or Director of Operations.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Internships and Practical Experience
                </h3>
                <div class="info-text">Gaining practical experience through internships is invaluable in hospitality
                    management. It allows you to apply what you've learned in the classroom to real-world situations,
                    develop your skills, and make industry connections that can lead to job opportunities.
                </div>
                <div class="info-text"><b>How Internships Can Boost Your Career:</b>
                    <ol class="list">
                        <li class="sub-list">Provide hands-on experience in different areas of hospitality.</li>
                        <li class="sub-list">Offer opportunities to network with industry professionals.</li>
                        <li class="sub-list">Help you identify your strengths and interests within the field.</li>
                    </ol>
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Internships-practical-experience.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/Internships-practical-experience.png">
                </div>
            </div>
        </div>

        <div id="Challenges" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Challenges in Hospitality Management</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Handling Customer Complaints
                </h3>
                <div class="info-text">Handling customer complaints effectively is a critical skill in hospitality
                    management. The key is to address issues promptly and professionally, turning a negative experience
                    into a positive one.
                </div>
                <div class="info-text"><b>Strategies for Effective Complaint Resolution:</b>
                    <ol class="list">
                        <li class="sub-list">Listen carefully to the customer's concerns.</li>
                        <li class="sub-list">Apologize sincerely and take responsibility.</li>
                        <li class="sub-list">Offer a solution or compensation that meets the customer's expectations.
                        </li>
                        <li class="sub-list">Follow up to ensure the issue has been resolved satisfactorily.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Managing Diverse Teams
                </h3>
                <div class="info-text">The hospitality industry is inherently multicultural, with teams often composed
                    of individuals from various backgrounds. Managing a diverse team requires cultural sensitivity,
                    open-mindedness, and the ability to foster an inclusive work environment.
                </div>
                <div class="info-text"><b>Challenges of Managing a Multicultural Workforce:</b>
                    <ol class="list">
                        <li class="sub-list">Navigating language barriers and communication differences.</li>
                        <li class="sub-list">Understanding and respecting cultural norms and practices.</li>
                        <li class="sub-list">Promoting diversity and inclusion within the workplace.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Keeping Up with Industry Trends
                </h3>
                <div class="info-text">The hospitality industry is constantly evolving, with new trends emerging
                    regularly. Staying updated on these trends is essential for staying competitive and meeting guests'
                    changing expectations.
                </div>
                <div class="info-text"><b>Importance of Staying Updated on Industry Trends:</b>
                    <ol class="list">
                        <li class="sub-list">Facilitates embracing innovations and applying best practices.</li>
                        <li class="sub-list">Helps you keep in step with the evolution of the wants of your customers.
                        </li>
                        <li class="sub-list">Ensures that your company is at the cutting edge of creativity.</li>
                    </ol>
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Industry-Trends.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/Industry-Trends.png">
                </div>
            </div>
        </div>

        <div id="Future-Trends" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Future Trends in Hospitality Management</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Sustainability in Hospitality
                </h3>
                <div class="info-text">Sustainable practices in hospitality seem to be on the rise with more guests
                    looking
                    for green options. It is not only good for the planet, but it also helps in building the image of
                    the
                    brand as well as the awareness within the audience that cares about the issues.
                </div>
                <div class="info-text"><b>Implementing Sustainable Strategies:</b>
                    <ol class="list">
                        <li class="sub-list">Cut down on wastage of energy and water usage as per the
                            environment-friendly
                            measures.</li>
                        <li class="sub-list">While catering for food and beverages use as many local and organic
                            products as
                            possible.</li>
                        <li class="sub-list">Have policies concerning waste management including waste minimization as
                            well
                            as recycling.</li>
                        <li class="sub-list">Encourage people to do things like reuse towels to promote green
                            initiatives to
                            other citizens.</li>
                    </ol>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Technology Integration
                </h3>
                <div class="info-text">Technological advancements have changed the way this industry operates in such a
                    way
                    that makes it efficient and pleasant for the clients. With the rise of self-check-in to customer
                    service
                    using artificial intelligence the integration of technology is crucial in remaining competitive in
                    the
                    business.
                </div>
                <div class="info-text"><b>Mentioning GoFryDay:</b>
                    <div class="info-text">
                        GoFryDay is a leader in Hotel management software, offering solutions that streamline operations
                        and
                        enhance guest satisfaction. Our innovative platforms help hotels manage everything from
                        reservations
                        to guest communications, ensuring a seamless experience.
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Personalization and Customer Experience
                </h3>
                <div class="info-text">Today, personalization is gaining importance in the hotel industry as more guests
                    require like the personalized services. Utilizing data and technology, hospitality managers can make
                    these experiences rewarding to guests and foster loyalty among them.
                </div>
                <div class="info-text"><b>How to Enhance Customer Experience Through Personalization:</b>
                    <ol class="list">
                        <li class="sub-list">Rely on guest information to provide insights and services.</li>
                        <li class="sub-list">Target communication and Luis to the tastes of the guests.</li>
                        <li class="sub-list">Give a very enjoyable service like room upgrade or amenities such as
                            personalized scents in the room.</li>
                    </ol>
                </div>
                <div class="demosection">
                    <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Discover How GoFryday Can Enhance Your Guest Experience Today!
                        </div>
                        <div>
                            <button class="book-btn"
                                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="info-text">In conclusion, hospitality management is a dynamic and multifaceted field that
                    blends the art of exceptional service with the science of efficient operations. From managing hotels
                    and restaurants to planning events and overseeing tourism, the scope of hospitality management is
                    vast, offering numerous career opportunities for those with the right skills and passion. Key areas
                    like customer service, leadership, financial management, and marketing are essential to success in
                    this industry, along with the ability to adapt to emerging trends such as sustainability and
                    technology integration.
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faq-container" id="faq-section" #faqSection>
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            {{ faq.answer }}
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFryDay.
    </div>
    <div>
        <button class="book-btn"
            onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>