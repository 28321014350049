import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { HotelService } from 'src/app/services/hotel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog5',
  templateUrl: './blog5.component.html',
  styleUrls: ['./blog5.component.css']
})
export class Blog5Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'What are the most common hotel automation technologies?',
      answer: 'Common hotel automation technologies include smart thermostats, lighting controls, curtains, mobile check-in/out, digital room keys, in-room tablets, building automation systems, energy-efficient appliances, surveillance systems, access control, and emergency response systems.',
      open: false
    },
    {
      question: 'How does hotel automation impact guest satisfaction?',
      answer: 'Hotel automation enhances guest satisfaction by offering convenience, efficiency, and luxury. Guests can enjoy streamlined check-in/out, personalized settings, faster service, reduced wait times, and advanced amenities.',
      open: false
    },
    {
      question: 'Is hotel automation affordable for small hotels?',
      answer: 'While the initial investment can be a barrier, the long-term benefits often outweigh the costs. Modern solutions offer flexibility for small hotels, and the increased efficiency, reduced energy consumption, and enhanced guest satisfaction can lead to higher revenue.',
      open: false
    },
    {
      question: 'What are the potential risks of hotel automation?',
      answer: 'Potential risks of hotel automation include technical issues, resistance to change, and high upfront costs. System failures, data breaches, privacy concerns, and staff adaptation challenges may arise.',
      open: false
    }
  ];

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Hotel-Automation',
      'Types-of-Hotel-Automation',
      'Hotel-CRM-Features',
      'Hotel-CRM-Benefits',
      'Fully-Automated-Hotel',
      'CRM-System',
      'Conclusion',
      'key-takeaways'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewLinkedIn() {
    window.open('https://www.linkedin.com/shareArticle?mini=true&url=https://gofryday.com/blog/automated-hotel', "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    this.disabled = true;
    this.isPopupOpen = true;
    let obj = {
      "NAME": this.contactUsForm.value.name,
      "EMAIL": this.contactUsForm.value.email,
      "MESSAGE": this.contactUsForm.value.message,
      "TNC": true
    }
    this.hotelService.contactUs(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        if (!!data?.object?.ID) {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '50%',
            data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(() => {
            this.isPopupOpen = false;
          });
        } else {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        }
        this.contactUsForm.reset();
      },
      error: (e) => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewHotelAutomation() {
    this.scrollToSection('Hotel-Automation');
  }

  viewTypesOfHotel() {
    this.scrollToSection('Types-of-Hotel-Automation');
  }

  viewHotelCrmFeatures() {
    this.scrollToSection('Hotel-CRM-Features');
  }

  viewHotelCrmBenefits() {
    this.scrollToSection('Hotel-CRM-Benefits');
  }

  viewFullyAutomatedHotel() {
    this.scrollToSection('Fully-Automated-Hotel');
  }

  viewCRMSystem() {
    this.scrollToSection('CRM-System');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }

  viewKeyTakeAways() {
    this.scrollToSection('key-takeaways');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
