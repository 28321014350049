import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FreeTrialComponent } from './pages/free-trial/free-trial.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { PlansComponent } from './pages/plans/plans.component';
import { FeaturesPageComponent } from './pages/features-page/features-page.component';
import { PartnerProgramComponent } from './pages/partner-program/partner-program.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ExperienceDetailComponent } from './pages/experience-detail/experience-detail.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { Blog1Component } from './pages/blog1/blog1.component';
import { Blog2Component } from './pages/blog2/blog2.component';
import { Blog3Component } from './pages/blog3/blog3.component';
import { Blog4Component } from './pages/blog4/blog4.component';
import { Blog5Component } from './pages/blog5/blog5.component';
import { Blog6Component } from './pages/blog6/blog6.component';
import { Blog7Component } from './pages/blog7/blog7.component';
import { Blog8Component } from './pages/blog8/blog8.component';
import { GuestAppComponent } from './pages/guest-app/guest-app.component';
import { ClioComponent } from './pages/clio/clio.component';
import { TaskAppComponent } from './pages/task-app/task-app.component';




const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomePageComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/"
    }
  },
  {
    path: 'free-trial',
    component: FreeTrialComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/free-trial"
    }
  },
  {
    path: 'features',
    component: FeaturesPageComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'features/hotel-guest-experience-software',
    component: GuestAppComponent,
    data: {
      title: "Guest App: Hotel Guest Experience Software | GoFryDay",
      description: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!",
      ogTitle: "Guest App: Hotel Guest Experience Software | GoFryDay",
      ogDescription: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!",
      canonicalUrl: "/features/hotel-guest-experience-software"
    }
  },
  {
    path: 'features/hospitality-management-software',
    component: ClioComponent,
    data: {
      title: "Clio: Best Hospitality Management Software | GoFryDay",
      description: "Streamline your hotel operations with our comprehensive hospitality management software to manage inventory, handle complaints. Contact us now!",
      ogTitle: "Clio: Best Hospitality Management Software | GoFryDay",
      ogDescription: "Streamline your hotel operations with our comprehensive hospitality management software to manage inventory, handle complaints. Contact us now!",
      canonicalUrl: "/features/hospitality-management-software"
    }
  },
  {
    path: 'features/hotel-task-management-software',
    component: TaskAppComponent,
    data: {
      title: "Task App: Hotel Task Management Software | GoFryDay",
      description: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!",
      ogTitle: "Task App: Hotel Task Management Software | GoFryDay",
      ogDescription: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!",
      canonicalUrl: "/features/hotel-task-management-software"
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: "About us - Leading Hotel Management Software Solutions",
      description: "Learn how GoFryDay empowers hotels to enhance guest experience and streamline operations with innovative AI-driven hospitality management software. Discover our mission, vision, and commitment to transforming the hotel industry.",
      ogTitle: "About us - Leading Hotel Management Software Solutions",
      ogDescription: "Learn how GoFryDay empowers hotels to enhance guest experience and streamline operations with innovative AI-driven hospitality management software. Discover our mission, vision, and commitment to transforming the hotel industry.",
      canonicalUrl: "/about-us"
    }
  },
  {
    path: 'plans',
    component: PlansComponent,
    data: {
      title: "Flexible Hospitality Management Software Plans",
      description: "Explore GoFryDay’s tailored plans to enhance hotel operations and guest experience. Whether you're a small boutique or a large chain, find the perfect hospitality management software to boost revenue and streamline processes.",
      ogTitle: "Flexible Hospitality Management Software Plans",
      ogDescription: "Explore GoFryDay’s tailored plans to enhance hotel operations and guest experience. Whether you're a small boutique or a large chain, find the perfect hospitality management software to boost revenue and streamline processes.",
      canonicalUrl: "/plans"
    }
  },
  {
    path: 'blog',
    component: BlogPageComponent,
    data: {
      title: "Blog - Hospitality Insights & Hotel Management Tips",
      description: "Stay updated with the latest trends in hotel management, guest experience, and hospitality technology. Explore expert tips and industry insights from GoFryDay to help your hotel thrive.",
      ogTitle: "Blog - Hospitality Insights & Hotel Management Tips",
      ogDescription: "Stay updated with the latest trends in hotel management, guest experience, and hospitality technology. Explore expert tips and industry insights from GoFryDay to help your hotel thrive.",
      canonicalUrl: "/blog"
    }
  },
  {
    path: 'blog/hicsa',
    component: Blog1Component,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/blog/hicsa"
    }
  },
  {
    path: 'blog/guest-centric-revolution',
    component: Blog2Component,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/blog/guest-centric-revolution"
    }
  },
  {
    path: 'blog/what-is-a-hotel-crm',
    component: Blog3Component,
    data: {
      title: "What Is A Hotel CRM & How It Maximizes Hotel Success?",
      description: "Discover how hotel CRM systems can enhance guest experience, improve operations, and increase revenue in the hospitality industry. Read Now!",
      ogTitle: "What Is A Hotel CRM & How It Maximizes Hotel Success?",
      ogDescription: "Discover how hotel CRM systems can enhance guest experience, improve operations, and increase revenue in the hospitality industry. Read Now!",
      canonicalUrl: "/blog/what-is-a-hotel-crm"
    }
  },
  {
    path: 'blog/features-of-a-crm-system-for-hotels',
    component: Blog4Component,
    data: {
      title: "9 Core Features of a CRM System for Hotels",
      description: "Explore the essential features of a CRM system that drive better customer relationships, streamline operations, and boost business efficiency. Read Now!",
      ogTitle: "9 Core Features of a CRM System for Hotels",
      ogDescription: "Explore the essential features of a CRM system that drive better customer relationships, streamline operations, and boost business efficiency. Read Now!",
      canonicalUrl: "/blog/features-of-a-crm-system-for-hotels"
    }
  },
  {
    path: 'blog/automated-hotel',
    component: Blog5Component,
    data: {
      title: "Automated Hotel: Benefits, Implementation & Risks",
      description: "Discover the benefits, implementation strategies, and risks of hotel automation. Learn how to enhance guest experience & streamline operations effectively",
      ogTitle: "Automated Hotel: Benefits, Implementation & Risks",
      ogDescription: "Discover the benefits, implementation strategies, and risks of hotel automation. Learn how to enhance guest experience & streamline operations effectively",
      canonicalUrl: "/blog/automated-hotel"
    }
  },
  {
    path: 'blog/hotel-profit-margin',
    component: Blog6Component,
    data: {
      title: "What is a Hotel Profit Margin? Strategies to Boost in 2024",
      description: "Learn what hotel profit margins are and discover effective strategies to boost your hotel’s profitability in 2024. Maximize revenue & control costs efficiently",
      ogTitle: "What is a Hotel Profit Margin? Strategies to Boost in 2024",
      ogDescription: "Learn what hotel profit margins are and discover effective strategies to boost your hotel’s profitability in 2024. Maximize revenue & control costs efficiently",
      canonicalUrl: "/blog/hotel-profit-margin"
    }
  },
  {
    path: 'blog/what-is-hospitality-management',
    component: Blog7Component,
    data: {
      title: "What Is Hospitality Management: Careers, Skills, and More",
      description: "Explore what is hospitality management, from its definition and importance to career opportunities, essential skills, and industry trends. Read now!",
      ogTitle: "What Is Hospitality Management: Careers, Skills, and More",
      ogDescription: "Explore what is hospitality management, from its definition and importance to career opportunities, essential skills, and industry trends. Read now!",
      canonicalUrl: "/blog/what-is-hospitality-management"
    }
  },
  {
    path: 'blog/how-to-improve-hotel-revenue',
    component: Blog8Component,
    data: {
      title: "13 Smart Strategies On How to Improve Hotel Revenue",
      description: "Discover actionable strategies on how to improve hotel revenue, from optimizing pricing and marketing to improving guest experience and leveraging technology.",
      ogTitle: "13 Smart Strategies On How to Improve Hotel Revenue",
      ogDescription: "Discover actionable strategies on how to improve hotel revenue, from optimizing pricing and marketing to improving guest experience and leveraging technology.",
      canonicalUrl: "/blog/how-to-improve-hotel-revenue"
    }
  },
  {
    path: 'partner-program',
    component: PartnerProgramComponent,
    data: {
      title: "Partner Program - Join Us & Enhance Hospitality Solutions",
      description: "Explore the GoFryDay Partner Program and discover opportunities to collaborate, expand your offerings, and drive growth. Partner with us to deliver cutting-edge hotel management solutions and enhance guest experiences.",
      ogTitle: "Partner Program - Join Us & Enhance Hospitality Solutions",
      ogDescription: "Explore the GoFryDay Partner Program and discover opportunities to collaborate, expand your offerings, and drive growth. Partner with us to deliver cutting-edge hotel management solutions and enhance guest experiences.",
      canonicalUrl: "/partner-program"
    }
  },
  {
    path: 'experiences',
    component: ExperienceComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/experiences"
    }
  },
  {
    path: 'experience-details',
    component: ExperienceDetailComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/experience-details"
    }
  },
  {
    path: 'reserve',
    component: ReserveComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/reserve"
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/privacy-policy"
    }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {
      title: "GoFryDay Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      canonicalUrl: "/terms-conditions"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }