<mat-toolbar class="toolbar fixed-header" *ngIf="!mobile">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div fxLayout="row">
            <span class="logo" (click)="selectHome()">
                <img src="assets/images/logo.svg" />
            </span>
            <span>
                <button class="home" [ngClass]="currentRoute == '/'? 'selected-btn' : 'home' " (click)="selectHome()">
                    Home
                </button>
            </span>
            <span>
                <button class="feature"
                    [ngClass]="currentRoute == '/features' || currentRoute == '/features/hotel-guest-experience-software' || currentRoute == '/features/hospitality-management-software' || currentRoute == '/features/hotel-task-management-software' ? 'selected-btn' : 'feature' "
                    (click)="selectFeatures()">Features</button>
            </span>
            <span>
                <button class="plans"
                    [ngClass]="currentRoute == '/plans' || currentRoute == '/plans#Pricing' || currentRoute == '/plans#Talk-to-Sales'? 'selected-btn' : 'plans' "
                    (click)="selectPricingPlans()">
                    Plans
                </button>
            </span>
            <span>
                <button class="about"
                    [ngClass]="currentRoute == '/about-us' || currentRoute == '/about-us#Vision' || currentRoute == '/about-us#What-We-Believe-In' || currentRoute == '/about-us#Team'? 'selected-btn' : 'about' "
                    (click)="selectAboutUs()">
                    About us
                </button>
            </span>
            <span>
                <button class="blog" [ngClass]="currentRoute == '/blog' || currentRoute == '/blog/hicsa' || currentRoute == '/blog/hicsa#correlationcontent' || currentRoute == '/blog/guest-centric-revolution' || currentRoute == '/blog/guest-centric-revolution#AI-In-Hospitality' ||
                    currentRoute == '/blog/what-is-a-hotel-crm' || currentRoute == '/blog/features-of-a-crm-system-for-hotels' || currentRoute == '/blog/automated-hotel' || currentRoute == '/blog/hotel-profit-margin' || currentRoute == '/blog/what-is-hospitality-management' || 
                    currentRoute == '/blog/how-to-improve-hotel-revenue'? 'selected-btn' : 'blog' "
                    (click)="selectBlog()">
                    Blog
                </button>
            </span>
            <span>
                <button class="about" [ngClass]="currentRoute == '/partner-program'? 'selected-btn' : 'program' "
                    (click)="selectPartnerProgram()">
                    Partner Program
                </button>
            </span>
        </div>
        <div>
            <span>
                <button class="experience"
                    [ngClass]="currentRoute == '/experiences' || currentRoute == '/experience-details' || currentRoute == '/reserve'? 'selected-btn' : 'program' "
                    (click)="selectExperiences()">
                    Experiences
                </button>
            </span>
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book a Demo
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-toolbar class="toolbar" *ngIf="mobile" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="logo" (click)="selectHome()">
        <img src="assets/images/logo.svg" />
    </span>
    <div>
        <button mat-button [matMenuTriggerFor]="menu" class="menu-btn">
            <span class="material-symbols-outlined">
                menu
            </span>
        </button>
        <mat-menu #menu="matMenu">
            <div class="menu-bar">
                <button mat-menu-item (click)="selectHome()">Home</button>
                <button mat-menu-item (click)="selectAboutUs()">About us</button>
                <button mat-menu-item (click)="selectBlog()">Blog</button>
                <div (click)="toggleFeatures($event)" class="custom-menu-item">
                    <span [ngClass]="{ 'active-features': showFeatures }">Features</span>
                    <span class="material-symbols-outlined">
                        {{ showFeatures ? 'expand_less' : 'expand_more' }}
                    </span>
                </div>
                <div class="sub-menu" *ngIf="showFeatures">
                    <button mat-menu-item (click)="selectGuestApp()">
                        <span class="sub-item">Guest App</span>
                    </button>
                    <button mat-menu-item class="sub-item" (click)="selectCLIO()">
                        <span class="sub-item">CLIO</span>
                    </button>
                    <button mat-menu-item class="sub-item" (click)="selectTaskApp()">
                        <span class="sub-item">Task App</span>
                    </button>
                </div>
                <button mat-menu-item (click)="selectPricingPlans()">Plans</button>
                <button mat-menu-item (click)="selectPartnerProgram()">Partner Program</button>
                <button mat-menu-item (click)="selectExperiences()">Experiences</button>
                <button mat-menu-item class="free-trial-btn-mob"
                    onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                    Book a Demo</button>
            </div>
        </mat-menu>
    </div>
</mat-toolbar>