import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  updateTitle(title: string) {
    if (!!title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string) {
    if (!!description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
  }

  updateOgTitle(ogtitle: string) {
    if (!!ogtitle) {
      this.meta.updateTag({ property: 'og:title', content: ogtitle });
    }
  }

  updateOgDescription(ogdescription: string) {
    if (!!ogdescription) {
      this.meta.updateTag({ property: 'og:description', content: ogdescription });
    }
  }

  // updateOgUrl(ogurl: string) {
  //   if (!!ogurl) {
  //     this.meta.updateTag({ property: 'og:url', content: ogurl });
  //   }
  // }

  updateCanonicalUrl(baseUrl: string, url: string) {
    const link: HTMLLinkElement = document.querySelector("link[rel='canonical']") || document.createElement('link');
    // const baseUrl = window.location.origin; 

    link.setAttribute('rel', 'canonical');
    const fullUrl = `${baseUrl}${url.startsWith('/') ? url : '/' + url}`;  
    link.setAttribute('href', fullUrl);

    if (!document.head.contains(link)) {
      document.head.appendChild(link);
    }
  }

}