import { Component, HostListener, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FeaturesPageComponent } from 'src/app/pages/features-page/features-page.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  mobile: boolean = false;
  currentRoute: string;
  showFeatures: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {
    this.currentRoute = this.router.url;
  }

  ngOnInit() {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  toggleFeatures(event: MouseEvent) {
    event.stopPropagation();
    this.showFeatures = !this.showFeatures;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  selectHome() {
    this.router.navigateByUrl('');
    window.scrollTo(0, 0);
  }

  selectAboutUs() {
    this.router.navigateByUrl('about-us');
    window.scrollTo(0, 0);
  }

  selectFeatures() {
    const dialogRef = this.dialog.open(FeaturesPageComponent, {
      width: '1155px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'guest-app') {
        this.router.navigateByUrl('features/hotel-guest-experience-software');
        window.scrollTo(0, 0);
      }
      else if (result == 'task-app') {
        this.router.navigateByUrl('features/hotel-task-management-software');
        window.scrollTo(0, 0);
      }
      else if (result == 'clio') {
        this.router.navigateByUrl('features/hospitality-management-software');
        window.scrollTo(0, 0);
      }
      else if (result == 'hotel-crm') {
        this.router.navigateByUrl('blog/what-is-a-hotel-crm');
        window.scrollTo(0, 0);
      }
      else if (result == 'revolution') {
        const navigationExtras: NavigationExtras = {
          fragment: 'AI-In-Hospitality'
        };
        this.router.navigate(['/blog/guest-centric-revolution'], navigationExtras).then(() => {

        });
      }
    })
  }

  selectGuestApp() {
    this.router.navigateByUrl('features/hotel-guest-experience-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectCLIO() {
    this.router.navigateByUrl('features/hospitality-management-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectTaskApp() {
    this.router.navigateByUrl('features/hotel-task-management-software');
    window.scrollTo(0, 0);
    this.showFeatures = false;
  }

  selectBlog() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  selectPricingPlans() {
    this.router.navigateByUrl('plans');
    window.scrollTo(0, 0);
  }

  selectPartnerProgram() {
    this.router.navigateByUrl('partner-program');
    window.scrollTo(0, 0);
  }

  selectExperiences() {
    this.router.navigateByUrl('experiences');
    window.scrollTo(0, 0);
  }

}